.gallery-grid {
    margin: 20px auto 20px auto;
    justify-content: center;
}

.gallery-grid img {
    display: block;
    height: 180px;
    width: auto;
    margin: 10px;
    border: white solid 10px;
    cursor: pointer;
    transition-duration: 0.3s;
}

.gallery-grid img:hover {
    opacity: 0.7;
}

.separator {
    border: white solid 3px;
    width: 90%;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
}