.modal-overlay {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
    margin: 0;
}
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 2;
    max-width: 800px;
    max-height: 100vh;
    width: min-content;
    background-color: white;
    overflow:scroll;
}

.modal-body img {
    max-width: 80vh;
    max-height: 90vh;
    width: auto;
    height: auto;
    border: 10px solid #fff;
}

.modal-body a {
    position: absolute;
    display: inline;
    color: black;
    text-decoration: none;
    line-height: 36px;
    font-size: 30px;
    font-weight: lighter;
    background: #fff;
    border-radius: 5px;
    height: 40px; width: 40px;
    text-align: center;
}
.modal-body .modal-close {
    right: 0; top: 0;
    border-radius: 0 0 0 5px;
}
.modal-body .modal-next,
.modal-body .modal-prev {
    right: 0; top: calc(50% - 25px);
    border-radius: 5px 0 0 5px;
    height: 50px;
    line-height: 40px;
    font-size: 60px;
}
.modal-body .modal-prev {
    left: 0;
    right: auto;
    border-radius: 0 5px 5px 0;
}
.modal-body {
    position: relative;
}

.modal-legend {
    margin-top: 0;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.modal-legend p {
    margin: 0;
}

.author-date {
    font-weight: bold;
}