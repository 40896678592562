main {

}

section {
    max-width: 890px;
    margin: 10px auto 10px auto;
    background-color: #f9f9f9;
    box-sizing: border-box;

    -moz-border-radius:7px;
    -webkit-border-radius:7px;
}

section h1 {
    padding: 10px;
    margin: 0;
    text-transform: uppercase;
}

section div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: fit-content;
}

section content {
    margin: 15px;
}

section div .video-container {
    width: max-content;
}

.video-container iframe {
    width: 100vw;
    height: 56.25vw;
}

.contact-container {
    flex-direction: column;
}

.contact-container img {
    margin: 30px;
}

.contact-container p {
    text-align: center;
}

.fa {
    margin: 20px;
    padding: 10px;
    font-size: 20px;
    width: 20px;
    text-align: center;
    text-decoration: none;
    border-radius: 50%;
    background: rgba(62, 62, 62, 0.98);
    color: white;
}

.fa:hover {
    opacity: 0.7;
}