footer {
    height: 100px;
    position: relative;
    text-align: center;
}

footer p {
    position: absolute;
    padding: 5px;
    margin: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}
