header {
    margin-top: 5px;
    padding-bottom: 5px;
    text-align: center;
}

header h1 {
    margin: 10px;
}

header p {
    font-size: 12;
    margin: 0px;
}