nav {
    text-align: center;
    padding: 10px;
}

li {
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
}

li a {
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    color: inherit;
}